<template>
    <div class="panel-list panel-list-new">
        <div class="row review-component">
            <b-card class="col-lg-12 main-content" v-cloak>
                <div class="panel-list">
                    <div class="panel-body">
                        <b-row class="panel-title">
                            <label class="col-lg-6 Intercompany-Agency">EMPLOYEE/REQUEST TYPE INFO </label>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col cols="lg-4">
                                <label>EMPLOYEE PHONE NUMBER</label>
                                <p v-if="!Request.EmployeePhoneNumber">
                                    N/A
                                </p>
                                <p>{{ Request.EmployeePhoneNumber }}</p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>Entity</label>
                                <p>{{ Request.Entity }}</p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>AGENCY</label>
                                <p>
                                    {{ Request.EmployeeAgency }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>REQUEST TYPE</label>
                                <p>{{ Request.RequestType }}</p>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="panel-body" v-if="Request.CompletedForms.length > 0">
                        <b-row class="panel-title">
                            <label class="col-lg-6 Intercompany-Agency">External Vendor Form</label>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col cols="lg-4">
                                <upload-file-holder :Files="Request.CompletedForms"></upload-file-holder>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="panel-body">
                        <b-row class="panel-title">
                            <label class="col-lg-6 Intercompany-Agency">SETUP INFO </label>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col cols="lg-4">
                                <label>VENDOR BUSINESS NAME </label>
                                <p>{{ Request.VendorBusinessName }}</p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>IDENTIFICATION Type</label>
                                <p v-if="Request.USTaxID">US Tax ID </p>
                                <p v-if="Request.Foreign">Foreign</p>
                                <p v-if="Request.SocialSecurityNumber">SOCIAL SECURITY NUMBER</p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>IDENTIFICATION NUMBER</label>
                                <p v-if="Request.USTaxID">{{
                                    formatIdentityType(Request[Request.IdentificationType]) }}</p>
                                <p v-if="Request.Foreign"> {{
                                    formatIdentityType(Request[Request.IdentificationType]) }}</p>
                                <p v-if="Request.SocialSecurityNumber"> {{
                                    formatIdentityType(Request[Request.IdentificationType]) }} </p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.IdentificationFiles != null">
                                <label>Attachment : {{ AttachmentLabel }}</label>
                                <upload-file-holder :Files="Request.IdentificationFiles"></upload-file-holder>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>Is this for former employee Cobra reimbursement?</label>
                                <p v-if="Request.FormerEmployeeReimbursement == true">
                                    Yes
                                </p>
                                <p v-if="Request.FormerEmployeeReimbursement == false">
                                    NO
                                </p>
                            </b-col>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col cols="lg-4">
                                <label>Should this vendor receive a 1099?</label>
                                <p v-if="Request.IsVendorReceive1099 == true || Request.IsVendorReceive1099 == 'true'">YES</p>
                                <p v-if="Request.IsVendorReceive1099 == false || Request.IsVendorReceive1099 == 'false'">NO</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.IsVendorReceive1099 == true">
                                <label>Should the 1099 be issued to the same name and EIN as the vendor we are remitting payment to?</label>
                                <p v-if="Request.IsIssuedNameEIN == true || Request.IsIssuedNameEIN == 'true'">YES</p>
                                <p v-if="Request.IsIssuedNameEIN == false || Request.IsIssuedNameEIN == 'false'">NO</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.IssuedName">
                                <label>Please confirm the name of the vendor that the 1099 should be issued to (please note that this name must appear on the first line of the vendors W-9).</label>
                                <p>
                                    {{ Request.IssuedName }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.IssuedName">
                                <label>Please confirm the TIN for this Vendor</label>
                                <p>
                                    {{ Request.VendorTIN }}
                                </p>
                            </b-col>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col cols="lg-4">
                                <label>VENDOR REMITTANCE NAME</label>
                                <p>
                                    {{ Request.VendorRemittanceName }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>COUNTRY</label>
                                <p>{{ Request.RemittanceCountry }}</p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>STREET ADDRESS LINE 1</label>
                                <p>{{ Request.StreetAddressLine1 }}</p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>STREET ADDRESS LINE 2</label>
                                <p>{{ Request.StreetAddressLine2 }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="this.Request.RemittanceCity">
                                <label>CITY</label>
                                <p>{{ Request.RemittanceCity }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="this.Request.RemittanceState">
                                <label>STATE</label>
                                <p>{{ Request.RemittanceState }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="this.Request.RemittanceZipCode">
                                <label>ZIP CODE</label>
                                <p>{{ Request.RemittanceZipCode }}</p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>VENDOR REMITTANCE EMAIL</label>
                                <p v-if="Request.RemittanceEmail">
                                    {{ Request.RemittanceEmail }}
                                </p>
                            </b-col>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col cols="lg-12" style="top:10px;position:relative;">
                                <label>Who at the vendor provided this information?</label>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>Name</label>
                                <p>
                                    {{ Request.InfoFromName }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>Title</label>
                                <p>
                                    {{ Request.InfoFromTitle }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>Telephone Number</label>
                                <p>
                                    {{ Request.InfoFromPhone }}
                                </p>
                            </b-col>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col cols="lg-4">
                                <label>How was this information obtained?</label>
                                <p>
                                    <span v-if="Request.InfoFromSourceIsVerbal">Verbal</span>
                                    <span
                                        v-if="Request.InfoFromSourceIsVerbal && Request.InfoFromSourceIsWritten">
                                        &
                                    </span>
                                    <span v-if="Request.InfoFromSourceIsWritten">Written</span>
                                </p>
                            </b-col>

                            <b-col cols="lg-4" v-if="Request.InfoFromSourceVerbal">
                                <label>Verbal</label>
                                <p>
                                    {{ Request.InfoFromSourceVerbal }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.InfoFromSourceWritten">
                                <label>Written</label>
                                <p>
                                    {{ Request.InfoFromSourceWritten }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.InfoFromSourceWritten">
                                <label>Written Communication File</label>
                                <upload-file-holder :Files="Request.SetupSourceFiles"></upload-file-holder>
                            </b-col>

                        </b-row>
                        <b-row class="panel-content">
                            <b-col cols="lg-4">
                                <label>Are there invoices already obtained that require immediate payment?</label>
                                <p>
                                    {{ Request.InvoicesImmediatePayment }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.InvoiceCopies.length > 0">
                                <label>Invoice Copy</label>
                                <upload-file-holder :Files="Request.InvoiceCopies"></upload-file-holder>
                            </b-col>
                        </b-row>
                    </div>

                    <div class="panel-body">
                        <b-row class="panel-title">
                            <label class="col-lg-6 Intercompany-Agency">BANKING INFO </label>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col cols="lg-4">
                                <label>Did the vendor indicate that they would like to be setup for ACH payments?</label>
                                <p v-if="Request.VendorFormIncludeACHBankingInfo == true || Request.VendorFormIncludeACHBankingInfo == 'true'">
                                    YES
                                </p>
                                <p v-if="Request.VendorFormIncludeACHBankingInfo == false || Request.VendorFormIncludeACHBankingInfo == 'false'">
                                    NO
                                </p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.HasBankLetterInvoice">
                                <label>Did the vendor provide a bank letter, invoice or voided check confirming their account information?</label>
                                <p v-if="Request.HasBankLetterInvoice == true || Request.HasBankLetterInvoice == 'true'">YES</p>
                                <p v-if="Request.HasBankLetterInvoice == false || Request.HasBankLetterInvoice == 'false'">NO</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.HasBankLetterInvoice == true">
                                <label>Bank Name</label>
                                <p>{{ Request.BankName }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.HasBankLetterInvoice == true">
                                <label>COUNTRY</label>
                                <p>{{ Request.BankCountry }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.HasBankLetterInvoice == true">
                                <label>BANK ADDRESS LINE 1</label>
                                <p>{{ Request.BankAddressLine1 }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.HasBankLetterInvoice == true">
                                <label>BANK ADDRESS LINE 2</label>
                                <p>{{ Request.BankAddressLine2 }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.BankState">
                                <label>BANK STATE</label>
                                <p>{{ Request.BankState }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.BankCity">
                                <label>BANK CITY</label>
                                <p>{{ Request.BankCity }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.BankZipCode">
                                <label>ZIP CODE</label>
                                <p>{{ Request.BankZipCode }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.HasBankLetterInvoice == true">
                                <label>Account name</label>
                                <p>{{ Request.BankAccountName }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.HasBankLetterInvoice == true">
                                <label>Account number</label>
                                <p>{{ Request.BankAccountNumber }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.HasBankLetterInvoice == true">
                                <label>Routing number</label>
                                <p>{{ Request.BankRoutingNumber }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.RemittanceAdviceEmail">
                                <label>REMITTANCE ADVICE EMAIL</label>
                                <p>{{ Request.RemittanceAdviceEmail }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.VendorFormIncludeACHBankingInfo == true">
                                <label>PLEASE ATTACH BANK LETTER, INVOICE, OR VOIDED CHECK.</label>
                                <div v-if="Request.WireInfoSheets.length > 0">
                                    <upload-file-holder :Files="Request.WireInfoSheets"></upload-file-holder>
                                </div>
                                <div v-else>
                                    <p>N/A</p>
                                </div>
                            </b-col>

                        </b-row>

                    </div>

                    <div class="panel-body">
                        <b-row class="panel-title">
                            <label class="col-lg-6 Intercompany-Agency">SERVICES & ANTICIPATED SPENT INFO </label>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col cols="lg-4">
                                <label>What product or service will this vendor be providing?</label>
                                <div>
                                    <p>{{ Request.TypeOfProductOrServiceProvidedByVendor }}</p>
                                </div>
                            </b-col>
                            <!-- <b-col cols="lg-4">
                                <label>WHAT PRODUCT OR SERVICE WILL THIS VENDOR BE PROVIDING</label>
                                <p>{{ Request.ProductServiceTypeOfProductOrServiceProvidedByVendor }}</p>
                            </b-col> -->
                            <b-col cols="lg-4">
                                <label>WHAT IS THE ANTICIPATED LEVEL OF BUSINESS DOLLARS SPEND YOU EXPECT TO CONDUCT WITH
                                    THIS VENDOR ANNUALLY? ENTER THAT AMOUNT IN THE PROVIDED SPACE (IF SERVICES ARE EXPECTED TO BE
                                    ONGOING, ESTIMATE ANNUAL SPEND)</label>
                                <p>${{ Request.DollarsAnticipatedToConductAnnuallyByVendor }}</p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>REQUIRED APPROVALS BASED OFF AMOUNTS BELOW
                                </label>
                                <p>
                                    $100
                                </p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.ServiceFiles != null && Request.ServiceFiles.length > 0">
                                <label>SERVICE INFO FILES</label>
                                <div>
                                    <upload-file-holder :Files="Request.ServiceFiles"></upload-file-holder>
                                </div>
                            </b-col>

                        </b-row>

                    </div>
                    <div class="panel-body">
                        <b-row class="panel-title">
                            <label class="col-lg-6 Intercompany-Agency">EMPLOYEE RELATIONSHIP INFO </label>
                        </b-row>
                        <b-row class="panel-content">
                            <template v-if="!IsNoneSelected">
                                <b-col cols="lg-4" v-for="item in Request.EmployeeVendorRelationship"
                                    :key="item.RelationshipName">
                                    <label>{{ GetRelationshipLabel(item) }}</label>
                                    <div>
                                        <p> {{
                                            Request.EmployeeVendorRelationshipDetails[item.RelationshipName]
                                        }}</p>
                                    </div>
                                </b-col>
                            </template>
                            <b-col cols="lg-4" v-if="IsNoneSelected">
                                <label>Employee Relationship With Vendor</label>
                                <p>None</p>
                            </b-col>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col cols="lg-4">
                                <label>Has any form of compensation or gifts of any type been offered to you or any person
                                    related to you
                                    in exchange for placing an order with this vendor?</label>
                                <p>{{ AnyGiftOrCompensationOfferedToYouToPlaceOrderYesNo }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-show="this.ShowCompensationDollars">
                                <label>Compensation Amount</label>
                                <p>{{ Request.CompensationDollers }}</p>
                            </b-col>
                        </b-row>
                        <b-row class="panel-content" v-if="Request.CompletedCode">
                            <b-col cols="lg-4">
                                <label>Completed Code</label>
                                <p>{{ Request.CompletedCode }}</p>
                            </b-col>
                        </b-row>
                        <div class="panel-body" v-if="VendorValidationFiles.length > 0">
                            <b-row class="panel-title">
                                <label class="col-6">Vendor Validation Attachment</label>
                            </b-row>
                            <b-row class="panel-content row">
                                <b-col :cols="ColumnsClass" v-if="VendorValidationFiles.length > 0">
                                    <label>Attachment(s)</label>
                                    <upload-file-holder :Files="VendorValidationFiles"></upload-file-holder>
                                    </b-col>
                            </b-row>
                        </div>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</template>


<style scope >
@media screen and (min-width: 1200px) {
    .icreview-component {
        padding: 1rem 3rem 0px !important;
        height: 600px !important;
    }
}

.file-ref {
    color: #00a1d2 !important;
}

.Agency-Name-HFM-Co {
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.73px;
    color: #4d5161;
}

.approval-side-section {
    background-color: rgba(162, 169, 173, 0.05);
    margin-bottom: 2px;
    padding-right: 35px !important;
    -webkit-box-shadow: 0 1px 3px 0 rgb(9 30 66 / 12%);
    box-shadow: 0 1px 3px 0 rgb(9 30 66 / 12%);
    border: solid 1px rgba(239, 242, 244, 0.2);
    border-left: none;
    border-radius: 0px 8px 8px 0px;
}

.row.page-title {
    height: 55px;
    background-color: #eef3f5 !important;
}

.row.page-title>label {
    padding: 14px 0px 14px 55px !important;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.81px;
    color: #4d5161;
    text-transform: none !important;
}

.file-ref {
    color: #00a1d2 !important;
}

.panel-list .panel-body .panel-title {
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    width: 100%;
    margin-left: 0px;
    margin-bottom: 25px !important;
}

.panel-list .panel-body .panel-title label {
    color: #77a2bb;
    margin-bottom: 0px !important;
    padding-top: 13px !important;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.57;
    letter-spacing: normal;
    padding-left: 0px !important;
    min-height: 35px;
}

.panel-list .panel-body .panel-title .btn {
    padding-left: 5px;
    padding-right: 5px;
}

.panel-list .panel-body .panel-title>div {
    padding-right: 0px !important;
    float: right;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.panel-list .panel-content label {
    text-transform: uppercase;
    color: #2c3865;
    font-size: 12px;
    margin-bottom: 1px !important;
    padding-left: 0px;
}

.panel-list .panel-content p {
    text-transform: uppercase;
    color: #4d5161;
    font-size: 14px;
    font-weight: 600;
}

.panel-content.row>div>label:first-child {
    color: #a2a9ad !important;
    font-family: "Open Sans" !important;
    font-size: 10px;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
}

.panel-content.row>div>*:not(:first-child) {
    font-family: "Open Sans" !important;
    font-size: 14px !important;
}

.panel-content.row>div>div {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.panel-list>.panel-body {
    margin-top: 1rem !important;
}

.panel-content.row>div {
    margin-bottom: 10px !important;
}

.edit-icon {
    height: 11px;
    width: 11px;
    color: #00a1d2;
}

.request-status {
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    height: 135px;
    border-radius: 5px;
}

.status-bar {
    line-height: 2;
    color: #d5d7e0;
}

.request-status .status {
    font-size: 12px;
    font-family: "Open Sans";
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.request-status .date {
    font-size: 10px;
    font-family: "Open Sans";
    font-weight: regular;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #a2a9ad;
}

.request-status .updatedby {
    font-size: 10px;
    font-family: "Open Sans";
    font-weight: regular;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.request-status .comments {
    font-size: 12px;
    font-family: "Open Sans";
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #00a1d2;
}
</style>


<script>

import UploadFileHolder from '../../Common/UploadFileHolder';
export default {
    components: {
        'UploadFileHolder': UploadFileHolder
    },
    data() {
        return {
            Error: false,
            Comments: "",
            id: null,
            RelationshipVendors: [{
                "label": "NONE",
                value: "NONE"
            },
            {
                "label": "Former Employee's Name",
                value: "FormerEmployeeName"
            },
            {
                "label": "Family Member's Name",
                value: "FamilyMemberName"
            },
            {
                "label": "Friend (name)",
                value: "FriendName"
            },
            {
                "label": "Vendor has a relationship w/ Agency Client",
                value: "VendorRelationshipWithAgencyClient"
            },
            {
                "label": "Former Employee’s Name",
                value: "VendorOfFormerEmployee"
            },
            {
                "label": "Former employee’s name of Agency",
                value: "VendorWasFormerEmployeeOfAgency"
            },
            {
                "label": "Name of employee with another Agency",
                value: "VendorHasRelationshipWithAnotherAgencyEmployee"
            },
            {
                "label": "Other",
                value: "Other"
            }
            ],
        };
    },

    mounted() {
        this.id = parseInt(this.ID);
        this.GetVendorDetails();
    },
    name: "Overview",
    props: {
        Request: {
            required: false,
            type: Object,
            default() {
                return {}
            }
        },
        VendorValidationFiles: {
            required: false,
            type: Object,
            default() {
                return {}
            }
        },
        DownloadableFiles: {
            type: Boolean,
            required: false,
            default: true,
        },
        ID: {
            type: Number,
            required: false,
            default: 0,
        },
        Columns: {
            type: Number,
            default: 4,
            required: false,
        }
    },
    computed: {
        AttachmentLabel() {
            return this.Request.IdentificationType == 'SocialSecurityNumber' || this.Request.IdentificationType == 'USTaxID' ? 'W-9 Attachment' : 'W-8BEN/W-8BEN-E Attachment';
        },
        IsNoneSelected() {
            let result = this.Request.EmployeeVendorRelationship.length == 1 && this.Request.EmployeeVendorRelationship.findIndex(val => val.RelationshipName == "NONE") > -1;
            return result;
        },
        GetInfoSource() {
            let text = "";
            if (this.Request.InfoFromSourceIsVerbal) {
                text += "Verbal"
            }
            if (this.Request.InfoFromSourceIsWritten) {
                text += text == ""
                    ? "Written"
                    : "& Written"
            }
            return text;
        },
        ShowCompensationDollars() {
            return this.Request.AnyGiftOrCompensationOfferedToYouToPlaceOrder == true;
        },
        AnyGiftOrCompensationOfferedToYouToPlaceOrderYesNo() {
            return this.Request.AnyGiftOrCompensationOfferedToYouToPlaceOrder == true ? "YES" : "NO";
        },
    },
    methods: {
        formatIdentityType(val) {
            if (val) {
                if (this.Request.IdentificationType == "USTaxID" && val.length > 2) {
                    if (val.indexOf("-") != 2) {
                        val = val.insert(2, '-');
                    }
                    return val;
                } else if (this.Request.IdentificationType == "SocialSecurityNumber") {
                    if (val.indexOf("-") != 3 && val.length > 3) {
                        val = val.insert(3, '-');

                    }
                    if (val.lastIndexOf("-") != 6 && val.length > 6) {
                        val = val.insert(6, '-');

                    }
                    const identificationType = val;
                    const lastFourDigits = identificationType.slice(-4); // Get last four digits
                    const maskedDigits = 'XXX'; // Specify the format for the first three digits
                    return maskedDigits + '-XX-' + lastFourDigits;
                } else {
                    return val;

                }
            } else {
                return val;
            }
        },
        GetFilesCategory() {
            return ["SampleMediaKitFiles", "CreditCardFiles", "VendorInvoiceCopyFiles", "IdentificationFiles", "SetupInfoSource", "CompletedForms"];
        },
        formatIdentityTypeLabel(val) {
            if (val) {
                if (this.Request.IdentificationType == "USTaxID") {
                    return "US Tax ID";
                } else if (this.Request.IdentificationType == "SocialSecurityNumber") {
                    return "Social Security Number";
                } else {
                    return val;

                }
            } else {
                return val;
            }
        },
        GetVendorDetails() {
            this.$store
                .dispatch("prodexpvendor/GetRequestWithTaskDetails", this.id)
                .then((res) => {
                    this.Request = res.data;
                    let prodexpvendorInfo = Object.assign({}, this.Request);
                    this.PrepareEmployeeVendorRelationship(prodexpvendorInfo);
                    this.LoadParentWithVendorRequestDetails(this.Request);
                    if(this.Request) {
                        this.$store
                            .dispatch("vendorValidation/GetVendorValidationDetails",{
                                id : this.id,
                                vendorType:"Production Expense",
                            })
                            .then((res) => {
                                if(res) {
                                    const vendorID = res.data.ID;
                                    this.VendorValidationFiles = res.data.Files;
                                    this.LoadParentWithVendorValidationAttemptCount(res.data.AttemptCounter , vendorID);
                                }
                            })
                    }
                })
                .catch((err) => {
                    if (err.response && err.response.status == 403) {
                        this.$router.push({
                            name: "error",
                            params: {
                                message: "UnAuthorized"
                            },
                        });
                    }
                    else {
                        this.Error = true;
                        alert(err);
                    }
                });
        },
        LoadParentWithVendorRequestDetails(val) {
            this.$emit("LoadProdEXPDetails", val);
        },
        LoadParentWithVendorValidationAttemptCount(val,val2) {
            this.$emit("LoadVendorValidationAttemptCount", val,val2);
        },
        PrepareEmployeeVendorRelationship(prodexpvendorInfo2) {
            if (this.IsNoneSelected) {
                let employeeRelationship = {
                    ID: 0,
                    RelationshipName: 'NONE'
                };
                prodexpvendorInfo2.EmployeeVendorRelationship = [];
                prodexpvendorInfo2.EmployeeVendorRelationship.push(employeeRelationship);
            } else {
                let employeeRelationships = [];

                prodexpvendorInfo2.EmployeeVendorRelationship.forEach(employeeRelations => {
                    employeeRelationships.push({
                        ID: 0,
                        RelationshipName: employeeRelations
                    });
                })
                prodexpvendorInfo2.EmployeeVendorRelationship = [];
                prodexpvendorInfo2.EmployeeVendorRelationship = employeeRelationships;
            }
        },
        GetRelationshipLabel(value) {
            let relationsshipIndex = this.RelationshipVendors.findIndex(val => {
                return val.value == value.RelationshipName;
            })
            if (relationsshipIndex > -1) {
                return this.RelationshipVendors[relationsshipIndex].label;
            } else {
                return "";
            }
        },
    },
};
</script>